export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path == "/login") {
    return;
  }
  // Authentication check
  const customer = useCustomer();
  if (customer.value === null) {
    await useFetchMe();
  }
  if (customer.value === null) {
    return navigateTo("/login");
  }
});
