import { default as _91id_93iicN4mCWiKMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/crawlerrors/[id].vue?macro=true";
import { default as indexhC1xG6v62LMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/crawlerrors/index.vue?macro=true";
import { default as _91id_930iH4WE3MkTMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/credentials/[id].vue?macro=true";
import { default as indexcNuyJRJ18fMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/credentials/index.vue?macro=true";
import { default as customerslu9Vn9DOeSMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/customers.vue?macro=true";
import { default as indexiJEqV8UKevMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/index.vue?macro=true";
import { default as itemsPIKrGyTr0xMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/items.vue?macro=true";
import { default as loginhFdgQSI28LMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/login.vue?macro=true";
import { default as sources_45reviewRwXFKClFcmMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/sources-review.vue?macro=true";
import { default as _91channel_93IGf3TC1NXhMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/sources/[channel].vue?macro=true";
import { default as indexNM2b6IA4TtMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/tasks/index.vue?macro=true";
import { default as indexHnc62ZteTxMeta } from "/builds/edukudo/eduge-input-man/frontend/pages/topics/index.vue?macro=true";
export default [
  {
    name: _91id_93iicN4mCWiKMeta?.name ?? "crawlerrors-id",
    path: _91id_93iicN4mCWiKMeta?.path ?? "/crawlerrors/:id()",
    meta: _91id_93iicN4mCWiKMeta || {},
    alias: _91id_93iicN4mCWiKMeta?.alias || [],
    redirect: _91id_93iicN4mCWiKMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/crawlerrors/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhC1xG6v62LMeta?.name ?? "crawlerrors",
    path: indexhC1xG6v62LMeta?.path ?? "/crawlerrors",
    meta: indexhC1xG6v62LMeta || {},
    alias: indexhC1xG6v62LMeta?.alias || [],
    redirect: indexhC1xG6v62LMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/crawlerrors/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930iH4WE3MkTMeta?.name ?? "credentials-id",
    path: _91id_930iH4WE3MkTMeta?.path ?? "/credentials/:id()",
    meta: _91id_930iH4WE3MkTMeta || {},
    alias: _91id_930iH4WE3MkTMeta?.alias || [],
    redirect: _91id_930iH4WE3MkTMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/credentials/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcNuyJRJ18fMeta?.name ?? "credentials",
    path: indexcNuyJRJ18fMeta?.path ?? "/credentials",
    meta: indexcNuyJRJ18fMeta || {},
    alias: indexcNuyJRJ18fMeta?.alias || [],
    redirect: indexcNuyJRJ18fMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/credentials/index.vue").then(m => m.default || m)
  },
  {
    name: customerslu9Vn9DOeSMeta?.name ?? "customers",
    path: customerslu9Vn9DOeSMeta?.path ?? "/customers",
    meta: customerslu9Vn9DOeSMeta || {},
    alias: customerslu9Vn9DOeSMeta?.alias || [],
    redirect: customerslu9Vn9DOeSMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/customers.vue").then(m => m.default || m)
  },
  {
    name: indexiJEqV8UKevMeta?.name ?? "index",
    path: indexiJEqV8UKevMeta?.path ?? "/",
    meta: indexiJEqV8UKevMeta || {},
    alias: indexiJEqV8UKevMeta?.alias || [],
    redirect: indexiJEqV8UKevMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: itemsPIKrGyTr0xMeta?.name ?? "items",
    path: itemsPIKrGyTr0xMeta?.path ?? "/items",
    meta: itemsPIKrGyTr0xMeta || {},
    alias: itemsPIKrGyTr0xMeta?.alias || [],
    redirect: itemsPIKrGyTr0xMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/items.vue").then(m => m.default || m)
  },
  {
    name: loginhFdgQSI28LMeta?.name ?? "login",
    path: loginhFdgQSI28LMeta?.path ?? "/login",
    meta: loginhFdgQSI28LMeta || {},
    alias: loginhFdgQSI28LMeta?.alias || [],
    redirect: loginhFdgQSI28LMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: sources_45reviewRwXFKClFcmMeta?.name ?? "sources-review",
    path: sources_45reviewRwXFKClFcmMeta?.path ?? "/sources-review",
    meta: sources_45reviewRwXFKClFcmMeta || {},
    alias: sources_45reviewRwXFKClFcmMeta?.alias || [],
    redirect: sources_45reviewRwXFKClFcmMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/sources-review.vue").then(m => m.default || m)
  },
  {
    name: _91channel_93IGf3TC1NXhMeta?.name ?? "sources-channel",
    path: _91channel_93IGf3TC1NXhMeta?.path ?? "/sources/:channel()",
    meta: _91channel_93IGf3TC1NXhMeta || {},
    alias: _91channel_93IGf3TC1NXhMeta?.alias || [],
    redirect: _91channel_93IGf3TC1NXhMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/sources/[channel].vue").then(m => m.default || m)
  },
  {
    name: indexNM2b6IA4TtMeta?.name ?? "tasks",
    path: indexNM2b6IA4TtMeta?.path ?? "/tasks",
    meta: indexNM2b6IA4TtMeta || {},
    alias: indexNM2b6IA4TtMeta?.alias || [],
    redirect: indexNM2b6IA4TtMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: indexHnc62ZteTxMeta?.name ?? "topics",
    path: indexHnc62ZteTxMeta?.path ?? "/topics",
    meta: indexHnc62ZteTxMeta || {},
    alias: indexHnc62ZteTxMeta?.alias || [],
    redirect: indexHnc62ZteTxMeta?.redirect || undefined,
    component: () => import("/builds/edukudo/eduge-input-man/frontend/pages/topics/index.vue").then(m => m.default || m)
  }
]