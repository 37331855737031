export default defineNuxtPlugin(() => {
  return {
    provide: {
      fullDate: function (dateStr: string) {
        if (dateStr === '0001-01-01T00:00:00Z') {
          return ''
        }
        return fullDate(new Date(dateStr));
      },
      coolDate: function (dateStr: string) {
        if (dateStr === '0001-01-01T00:00:00Z') {
          return ''
        }
        return coolDate(new Date(dateStr));
      },
      onlyDate: function (dateStr: string) {
        if (dateStr === '0001-01-01T00:00:00Z') {
          return ''
        }
        return onlyDate(new Date(dateStr));
      },
      onlyTime: function (dateStr: string) {
        if (dateStr === '0001-01-01T00:00:00Z') {
          return ''
        }
        return onlyTime(new Date(dateStr));
      },
      formatDuration: function (sec_num: number) {
        return formatDuration(sec_num)
      }
    },
  };
});

const formatDuration = function (sec_num: number) {
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  let hoursStr = hours.toString();
  let minutesStr = minutes.toString();
  let secondsStr = seconds.toString();
  if (hours < 10) { hoursStr = "0" + hours; }
  if (minutes < 10) { minutesStr = "0" + minutes; }
  if (seconds < 10) { secondsStr = "0" + seconds; }
  return hoursStr + ':' + minutesStr + ':' + secondsStr;
}
const fullDate = function (date: Date) {
  return date.toLocaleString("mn-MN");
};

const coolDate = function (date: Date) {
  let now = new Date()
  if (onlyDate(date) == onlyDate(now)) {
    return "Өнөөдөр " + onlyTime(date)
  }

  let yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1);
  if (onlyDate(date) == onlyDate(yesterday)) {
    return "Өчигдөр " + onlyTime(date)
  }

  var seconds = Math.floor((now.valueOf() - date.valueOf()) / 1000);
  if (seconds < 3600) {
    let minutesAgo = Math.floor(seconds / 60);
    if (minutesAgo >= 1) {
      if (minutesAgo == 1) {
        return "Минутийн өмнө";
      }
      return minutesAgo + ' ' + "минутийн өмнө";
    } else {
      return "Дөнгөж сая";
    }
  }

  return fullDate(date)
}

const onlyDate = function (date: Date) {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

const onlyTime = function (date: Date) {
  let dateTime = new Date(date);
  let hour = ("0" + dateTime.getHours()).slice(-2);
  let minute = ("0" + dateTime.getMinutes()).slice(-2);
  return hour + `:` + minute;
};
